* {
    /* transition: all 0.25s ease-in-out 0s; */
    font-family: Nunito;
}

.share-button {
    background: #1877f2;
    border-radius: 3px;
    border: none;
    color: #fff;
    font-size: 11px;
    padding: 2px;
    font-style: bold;
}

.share-button span {
    padding-left: 4px;
    padding-right: 4px;
    vertical-align: middle;
}

.share-button img {
    vertical-align: middle;
}

.border-card {
    background: #c40000;
}

section {
    height: 100%;
}

._form-05-box {
    width: 800px;
    height: 400px;
    /* margin: 200px; */
}

._form-05-box .row {
    display: flex;
    height: 100%;
    flex: 1;
}

._form-05-box-a {
    overflow: hidden;
}

.signup ._form-05-box {
    height: auto;
}

.form-group a {
    color: #b0000b;
}

.form-group ._btn_04 {
    background: #b0000b;
}

.form-group ._btn_04 a {
    color: #fff;
}

.login .form-05-box-a {
    background: #000;
    width: 100%;
    padding: 0;
}

.login ._lk_nb {
    width: 82%;
}

.login input {
    margin: 0px 10px;
    font-size: 18px;
}

.signup ._lk_nb {
    width: 100%;
}

._form-05-box .col-sm-5 {
    flex: 0 0 50%;
    max-width: 50%;
}

._form-05-box .col-sm-7 {
    flex: 0 0 50%;
    max-width: 50%;
}

.login .main-head {
    margin: 0;
}

.login .main-head h2 {
    margin: 0;
    font-size: 32px;
    font-weight: 610;
}

.signup .form-group {
    padding: 10px 0px;
}

.signup input {
    margin: 0;
    font-size: 18px;
}

.text-light {
    color: #fff !important;
}

@media only screen and (max-width: 580px) {
    ._form-05-box .row {
        flex-direction: column-reverse;
    }
    .form-05-box-a {
        padding: 0px 20px;
    }
    ._form_05_logo p {
        margin: 10px 0 0 0;
    }
    ._form_05_socialmedia {
        width: 70%;
        padding-left: 50px;
    }
    .btn_margin_top {
        margin-top: 10px;
    }
    .btn_flex_direction_column {
        flex-direction: column;
    }
    /*
       ._form_05_socialmedia ol {
         display: flex;
         flex-direction: row;
       }

      ._form_05_socialmedia ol li{
        width:40px;
        height:40px;
        border-radius: 50%;
        float: left;
        margin: 5px;

      }

        ._form_05_socialmedia ol li{
          align-self: center;
        }

     ._form_05_socialmedia span{
       display:  none;
     } */
}

@media only screen and (max-width: 430px) {
    ._form-05-box ._lk_nb {
        display: none;
    }
    ._form_05_socialmedia {
        width: 10%;
        height: 10%;
        border-radius: 50%;
        padding-left: 40px;
    }
    ._form_05_socialmedia ol {
        display: flex;
        flex-direction: row;
    }
    ._form_05_socialmedia ol li {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        float: left;
        margin: 5px;
    }
    ._form_05_socialmedia ol li {
        align-self: center;
    }
    ._form_05_socialmedia span {
        display: none;
    }
    ._form_05_socialmedia ol li i {
        left: 13px;
    }
}

@media only screen and (max-width: 355px) {
    .login {
        font-size: 10px !important;
    }
    .form-group a {
        font-size: 10px;
    }
    [type="checkbox"]+label {
        font-size: 10px;
    }
    ._form_05_socialmedia {
        padding-left: 0px;
    }
}


/*pickup form  */

.pickup-form-div {
    margin: 5% 0 5% 25%;
    border-radius: 12px;
    width: 58%;
    border: 1px solid #789;
    background: #fff;
}

.pickup-form {
    width: 100%;
    padding: 10px;
    display: inline-block;
    /* justify-content: space-between; */
}

.pickup-form-field label {
    margin: 0px 18px;
    font-size: 16px;
    width: 242px;
    color: #222;
}

.pickup-form .ant-radio-group label {
    width: 100px;
}

.pickup-form-field {
    display: flex;
    flex-direction: column;
    flex: 1;
    float: left;
}

.pickup-form-field input {
    width: 77%;
    border: 1px solid #666;
    /* border-radius: 10px; */
    margin: 20px;
    padding-left: 20px;
}

.pickup-form-field textarea {
    width: 100%;
    border: 1px solid #666;
    margin: 0px 20px 20px 20px;
    margin-bottom: 20px;
    height: 70px;
    padding-left: 20px;
}

.pickup-form-field .ant-form-items TextArea {
    width: 100%;
    border: 1px solid #666;
    margin: 0px 20px 20px 20px;
    margin-bottom: 20px;
    padding-left: 20px;
}

.pickup-title {
    border-radius: 12px 12px 0px 0px;
    background: #b0000b;
}

.pickup-title h2 {
    color: #fff;
    padding: 18px;
    font-size: 20px;
    text-align: center;
    /* border-radius: 12px; */
}

.confirm {
    text-align: left;
    padding: 20px;
    font-size: 18px;
}

.pickup-form-field .ant-row {
    /* width:20%; */
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
}

.pickup-form-field .ant-form {
    display: inline-block;
}

.pickup-form-field .ant-form-item-label {
    text-align: left;
    overflow: visible;
    width: auto;
}

.pickup-form-field Button {
    width: 192px;
    height: auto;
    margin: 55px 80% 10px 50px;
    font-size: 20px;
    background: #8a6;
}

.pickup-form .radio {
    /* float:none; */
    display: flex;
    flex-direction: row;
    width: 100%;
}

.pickup-form .radio .ant-radio-group {
    /* margin-left:30px; */
}

.pickup-form .radio li {
    width: 600px;
    list-style-type: disc;
    /* margin-left: 30px; */
    font-size: 16px;
}

.pickup-form .radio form-item {
    float: right;
}

.pickup-form .radio ul li {
    margin-left: 50px;
}

.radio span {
    font-size: 16px;
    margin: 15px 5px 0 5px;
}

.pickup-form-field input {
    width: 60%;
}

.pickup-form .ant-radio-wrapper {
    padding: 0px 15px 0 15px;
    width: 14px;
    float: left;
    border-radius: 50%;
    margin-right: 60px;
}

.pickup-form .ant-radio-button-wrapper:not(:first-child)::before {
    width: 0px;
}

.pickup-form .ant-form-item-label>label::after {
    content: "";
}

.pickup-form .ant-form-item-label>label {
    font-size: 16px;
}

@media only screen and (max-width: 470px) {
    .pickup-form-div {
        width: 69%;
    }
    .pickup-form {
        width: 100%;
    }
    .pickup-form-field input {
        margin-left: 10px;
    }
    .pickup-title h2 {
        font-size: 22px;
    }
    .pickup-form-field label {
        margin-right: 21%;
    }
    .pickup-form-field Button {
        margin: 5px 0 10px 50px;
        font-size: 16px;
        width: 98%;
    }
    .pickup-form .status label {
        margin-right: 20%;
    }
}

@media only screen and (max-width: 285px) {
    .pickup-form-div {
        width: 74%;
    }
    .pickup-form-field input {
        margin-left: 0px;
    }
    .pickup-title h2 {
        font-size: 22px;
    }
    .pickup-form .radio .ant-radio-group {
        display: flex;
        margin-left: 0px;
    }
    .pickup-form .radio li {
        font-size: 10px;
    }
    .pickup-form .radio span {
        margin-left: 10px;
        font-size: 10px;
    }
    .pickup-form .status label {
        margin-right: 11%;
    }
    .pickup-form .status span {
        font-size: 16px;
    }
}


/* form */

.ant-btn {
    border: 1px solid #b0000b;
}

.ant-btn:hover {
    border: 1px solid #b0000b;
}

.ant-btn:focus {
    border: 1px solid #b0000b;
}

.ant-btn-primary {
    background: #b0000b !important;
    border: none;
    padding: 0 25px;
    color: #fff;
}

.ant-btn-primary:hover {
    background: #b0000b;
    color: #fff;
}

.ant-btn-primary:focus {
    background: #b0000b !important;
    color: #fff;
}

.ant-input:hover {
    border-bottom: 1px solid #000;
}

.ant-picker-focused {
    border: 1px solid #b0000b !important;
}

.ant-picker:hover {
    border: 1px solid #000;
}

.ant-radio:hover {
    border-color: #b0000b !important;
}

.ant-radio:focus {
    border-color: #b0000b !important;
}

.ant-radio-checked .ant-radio-inner {
    border-color: #b0000b !important;
}

.ant-radio-inner::after {
    background: #b0000b !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background: #b0000b !important;
    border-color: #b0000b !important;
}


/* dashboard */

@media only screen and (max-width: 730px) {
    .dash-pickup-button {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
    }
    .dash-pickup-button .ant-btn span {
        font-size: 12px;
        padding: 0px 0.5em;
    }
}

@media only screen and (max-width: 380px) {
    .dash-pickup-button {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }
    .dash-pickup-button .ant-btn {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        padding: 0px;
    }
    .dash-pickup-button .ant-btn span {
        font-size: 14px;
        padding: 0px 0.4em;
    }
}

.pickup_btn {
    background: #b0000b;
    height: auto;
    width: auto;
    padding: 5px 20px;
    margin-right: 10px;
    border-radius: 10px;
    text-align: center;
    font-size: 18px;
    color: #fff;
    font-weight: 600;
}

.font-lob {
    font-family: Lobster;
}

.ant-input {
    -webkit-box-sizing: border-box !important;
    box-sizing: border-box !important;
    margin: 0 !important;
    padding: 0 !important;
    font-variant: tabular-nums !important;
    list-style: none !important;
    -webkit-font-feature-settings: "tnum" !important;
    font-feature-settings: "tnum", "tnum" !important;
    position: relative !important;
    display: inline-block !important;
    width: 100% !important;
    min-width: 0 !important;
    padding: 4px 11px !important;
    color: rgba(0, 0, 0, 0.85) !important;
    font-size: 14px !important;
    line-height: 1.5715 !important;
    background-color: #fff !important;
    background-image: none !important;
    border: 1px solid #d9d9d9 !important;
    border-radius: 2px !important;
    -webkit-transition: all 0.3s !important;
    transition: all 0.3s !important;
}

input:focus {
    box-shadow: none !important;
}

.ant-form-item-has-error .ant-input:focus,
.ant-form-item-has-error .ant-input-affix-wrapper:focus,
.ant-form-item-has-error .ant-input-focused,
.ant-form-item-has-error .ant-input-affix-wrapper-focused {
    border-right-width: 1px !important;
    border-color: #ff7875 !important;
    outline: 0 !important;
    -webkit-box-shadow: 0 0 0 2px rgb(255 77 79 / 20%) !important;
    box-shadow: 0 0 0 2px rgb(255 77 79 / 20%) !important;
}

.m-0 {
    margin: 0px !important;
}

.protax-btn {
    font-size: 1rem !important;
    /* padding: 5px 50px !important; */
    background: #b0000b !important;
    border-radius: 5px !important;
    border: none !important;
}

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
    background-color: #fff !important;
    border-color: #ff4d4f !important;
}

#dynamic_rule_dob,
#dynamic_rule_preferredDate {
    margin: 0px !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.mr-2 {
    margin-right: 1rem;
}

.mr-1 {
    margin-right: 0.75rem;
}

.ant-steps-item-icon {
    background: #b0000b !important;
    border-color: rgba(0, 0, 0, 0.25) !important;
}

.ant-steps-item-wait .ant-steps-item-icon {
    background-color: #fff !important;
    border-color: rgba(0, 0, 0, 0.25) !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
    background-color: #fff !important;
    border-color: #b0000b !important;
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
    color: #b0000b !important;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
    background-color: #b0000b !important;
}

.d-flex {
    display: flex !important;
}

.align-items-center {
    align-items: center !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.dependant_num {
    height: 40px !important;
    width: 40px !important;
    border-radius: 20px !important;
    background-color: ivory !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.dependant_num:hover {
    cursor: pointer;
    box-shadow: 0 0 5px 2px #000000 !important;
}

.p-1 {
    padding: 0.5rem !important;
}

.p-4 {
    padding: 1rem !important;
}

.mw-340 {
    min-width: 340px !important;
}

.mxw-300 {
    max-width: 300px !important;
}

.mt-2 {
    margin-top: 1rem !important;
}

@media (min-width: 992px) {
    .modal-lg {
        width: 900px;
    }
}

@media (max-width: 991px) {
    .modal-lg {
        width: 80vw;
    }
}


/* @media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
} */

.text-uppercase {
    text-transform: uppercase !important;
}

.text-grey-dark {
    color: #888 !important;
}

.text-grey {
    color: #888 !important;
}

.mb-1 {
    margin-bottom: 1rem;
}

.ant-upload.ant-upload-select-picture-card:hover {
    border-color: #b0000b !important;
}

.checklistImageContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.checklistImage {
    height: 150px;
    width: 150px;
    border-radius: 10px;
    margin-left: 10px;
    object-fit: contain;
    position: relative;
    margin-bottom: 10px !important;
}

.relative {
    position: relative !important
}

.checklistCross {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #ccc;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: #b0000b !important;
}

.ant-btn:hover,
.ant-btn:focus {
    background: #fff;
    border-color: #b0000b;
}

.spinner-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
}

.mb-2 {
    margin-bottom: 1rem !important;
}

.d-flex {
    display: flex !important;
}

.flex-column {
    flex-direction: column !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.text-bold {
    font-weight: bold !important;
}

.text-right {
    text-align: right !important;
}

.text-justify {
    text-align: justify !important;
}

.refund-box {
    padding: 10px;
    background-color: #00c851;
    color: #fff;
    margin-top: 10px;
    font-size: 15px;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.text-center {
    text-align: center !important;
}

.side_logo_login {
    /* padding: 20px;
  text-align: center;
  margin: auto auto;
  padding-top: 25px; */
}

.py-5 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

.py-6 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
}

.text-left {
    text-align: left !important;
}

.resend_otp {
    margin-top: 5px !important;
    font-weight: 600;
}

.resend_otp:hover {
    cursor: pointer !important;
}

.my-2 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.my-card {
    box-shadow: 2px 2px 10px rgb(0 0 0 / 20%);
    padding: 15px;
    border-radius: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.justify-content-between {
    justify-content: space-between !important;
}

.d-flex {
    display: flex !important;
}

.align-items-center {
    align-items: center;
}

.btn-round {
    border-radius: 50% !important;
    height: 40px !important;
    width: 40px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0px !important;
}

.text-semibold {
    font-weight: 600 !important;
}

.h-100 {
    height: 100% !important;
}

@media screen and (max-width: 767px) {
    .mt-sm-1 {
        margin-top: 0.5rem !important;
    }
}

.percent-chart-container {
    margin-top: 20px !important;
}

.percent-chart {
    width: 150px !important;
    margin: auto !important;
    object-fit: contain;
}

.btn-success {
    background-color: #00c851 !important;
}

.btn-success:focus {
    background-color: #00c851 !important;
}

.btn-dark {
    background-color: #2e2e2e !important;
}

.btn-info {
    background-color: #33b5e5 !important;
}

.btn-info:focus {
    background-color: #2994bb !important;
}

.btn-sm {
    padding: 0px 10px !important;
}

.ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
    background: #b0000b !important;
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
    background: #b0000b !important;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: #b0000b !important;
}

.mt-3 {
    margin-top: 1rem !important;
}

.mt-1 {
    margin-top: 0.5rem !important;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.doc-image-current {
    width: 360px !important;
}

.w-100 {
    width: 100% !important;
}

.sigCanvas {
    /* height: 100px; */
    /* width: 200px; */
}

.signature-bottom-container {
    background-color: #ccc;
    padding: 10px;
}

.react-responsive-modal-modal {
    padding: 0px !important;
    max-width: 1024px !important;
}

.choose-color {
    height: 30px;
    width: 30px;
    border-radius: 15px;
    background-color: #000;
    margin-right: 15px;
}

.choose-color:hover {
    cursor: pointer !important;
}

.cursor-pointer:hover {
    cursor: pointer !important;
}

.choose-color-selected {
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.5) !important;
}

.ant-spin-dot-item {
    background-color: #b0000b !important;
}


/* .document-signature {
  bottom: 152px !important;
  height: 12px !important;
  width: 35px !important;
  left: 42px !important;
  background: #fff !important;
}

.document-signature-2 {
  bottom: 198px !important;
  height: 12px !important;
  width: 35px !important;
  left: 82px !important;
  background: transparent !important;
} */

.doc-parent {
    transform: scale(1) !important;
}

.ml-1 {
    margin-left: 0.5rem !important;
}

.transaction-box {
    box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
}

.transaction-box-child {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.text-grey {
    color: #ccc !important;
}

.ml-2 {
    margin-left: 10px !important;
}

.StripeElement--webkit-autofill {
    background: transparent !important;
}

.StripeElement {
    width: 100%;
    padding: 11px 15px 11px 0;
    border: 1px solid #ccc;
    padding: 10px;
}

.mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
    border-color: #b0000c3d !important;
    box-shadow: 0 0 0 2px rgb(0 0 0 / 20%) !important;
}

.ant-input-affix-wrapper:hover {
    border-color: #b0000c3d !important;
    border-right-width: 1px !important;
    z-index: 1;
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
    padding: 0 0 0px !important;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
}

.text-dark {
    color: #000 !important;
}

.file-field {
    margin-top: 0px !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.scheduleAppointment {
    margin-top: 35px;
    background: #5fb8b9;
    color: #fff;
    padding: 8px 12px;
    border: 0px;
    -webkit-box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.15) inset;
    -moz-box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.15) inset;
    box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.15) inset;
    border-radius: 4px;
    text-decoration: none;
    display: inline-block;
}

.text-white {
    color: #fff;
}

input:not([type]):focus:not([readonly]),
input[type="text"]:focus:not([readonly]),
input[type="password"]:focus:not([readonly]),
input[type="email"]:focus:not([readonly]),
input[type="url"]:focus:not([readonly]),
input[type="time"]:focus:not([readonly]),
input[type="date"]:focus:not([readonly]),
input[type="datetime"]:focus:not([readonly]),
input[type="datetime-local"]:focus:not([readonly]),
input[type="tel"]:focus:not([readonly]),
input[type="number"]:focus:not([readonly]),
input[type="search"]:focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
    border-bottom: 1px solid #b0000c85 !important;
    box-shadow: 0 1px 0 0 #b0000c94 !important;
}

a:hover {
    color: #b0000c !important;
}

.character-counter {
    display: none !important;
}

.red-text {
    color: #c40000 !important;
}

.acuity-embed-button {
    background-color: #8339b0 !important;
}

.acuity-embed-button:hover {
    color: #ffffff !important;
}

.family-old {
    font-family: "Roboto", sans-serif;
}

.dropOffModal {
    background: transparent !important;
}

.pdf-container {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    height: 100%;
}

.d-none {
    display: none !important;
}

.mr-3 {
    margin-right: 10px !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.ant-image-img {
    object-fit: contain !important;
}

.questionInputBox {
    width: 200px !important;
}

.calculator-button-open {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99999999;
    height: 50px;
    width: 50px;
    border-radius: 25px;
    background-color: #b0000b;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 1px 1px 47px -6px rgba(0, 0, 0, 0.54);
}

.calculator-container {
    position: fixed;
    bottom: 80px;
    width: 15rem;
    background: transparent;
    right: 20px;
    z-index: 99999;
}

.react-calc {
    width: 100% !important;
}

.react-calculator .s1 {
    width: 100% !important;
    margin-bottom: 0px !important;
}

.fa-xl {
    font-size: 25px;
}

.idproof-image-container {
    height: 200px;
    width: 400px;
    background-color: #aaaaaa62;
    border-radius: 10px;
    box-shadow: 1px 6px 18px -4px rgba(0, 0, 0, 0.58);
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin: 20px auto;
    object-fit: contain;
}

.idproof-image {
    height: 200px;
    width: 400px;
    border-radius: 10px;
    box-shadow: 1px 6px 18px -4px rgba(0, 0, 0, 0.58);
    object-fit: cover;
}

.id-proof-delete {}

.login-image {
    margin-bottom: 20px;
}

.text-grey-900 {
    color: rgb(39, 39, 39) !important;
}

.react-pdf__Page canvas {
    border: 1px solid #ccc !important;
}

.dosign-loader {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.35);
    top: 0;
    left: 0;
    z-index: 99999;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    display: flex;
}

.dosign-cont {
    min-width: 300px;
    max-width: 500px;
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 24px;
}